module.exports = {
  selectLanguage: 'Select your language',
  Language: 'EN',
  tags: 'Tags',
  'tags.allTagsLink': 'Browse all tags',
  'tags.intro': 'Here you can find all the tags',
  'tags.blog.intro': 'Here you can find all the blog tags',
  'tags.pages.intro': 'Here you can find all the tags in the pages',
  'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } tagged with`,
  header: 'menu',
  'header.shop': 'Shop',
  'header.events': 'Events',
  'header.app': 'App',
  'header.blog': 'Blog',
  'header.language': 'EN',
  home: 'Home',
  'home.hero.shop': 'Shop',
  'home.hero.learn': 'Learn',
  'home.hero.discover': 'Discover',
  'home.hero.title': 'Discover magical Georgian wines!',
  'home.hero.subtitle':
    'Georgian wine can be elusive and hard to find. So Georgian Wine Finder helps you find wines in your home country, and can help you discover magical and rare wines on your next adventure to Georgia!',
  'home.download.title': 'Download & Discover',
  'home.download.subtitle':
    'Available to download for FREE on IOS and Android Devices!',
  'home.app.title': 'Georgian Wine Finder',
  'home.app.subtitle':
    'Available to download for FREE on IOS and Android Devices!',
  'home.events': 'Events',
  'home.blog': 'Blog',
  not_found: '404 - Page Not Found',
  '404': 'You are arrived to an unvailable page or you have missed the path...',
  georgian: 'Georgian.wine',
  copyright: 'Copyright © All rights reserved',
}
