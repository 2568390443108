module.exports = {
  selectLanguage: 'Select your language',
  Language: 'EN',
  tags: 'Tags',
  'tags.allTagsLink': 'Browse all tags',
  'tags.intro': 'Here you can find all the tags',
  'tags.blog.intro': 'Here you can find all the blog tags',
  'tags.pages.intro': 'Here you can find all the tags in the pages',
  'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } tagged with`,
  header: 'menu',
  'header.services': 'Services',
  'header.work': 'Work',
  'header.blog': 'Blog',
  'header.language': 'KA',
  home: 'Home',
  'home.download.title': 'Download & Discover',
  'home.download.subtitle':
    'Available to download for FREE on IOS and Android Devices!',
  'home.app.title': 'Georgian Wine log in now',
  'home.app.subtitle':
    'Available to download for FREE on IOS and Android Devices!',
  not_found: '404 - Page Not Found',
  '404': 'You are arrived to an unvailable page or you have missed the path...',
  georgian: 'Georgian.wine',
  copyright: 'Copyright © All rights reserved',
}
