import React from 'react'
import Logo from '../../img/logo.png'
import select from '../../components/utils'
import menuTree from '../../data/menuTree'
import { FormattedMessage } from 'react-intl'
import Link from 'gatsby-link'

const Header = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: '',
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active,
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                          navBarActiveClass: 'is-active',
                      })
                    : this.setState({
                          navBarActiveClass: '',
                      })
            }
        )
    }

    render() {
        const props = this.props
        const sel = select(props.langKey)
        const lang = `${props.langKey !== 'en' ? props.langKey : ''}`
        return (
            <nav
                className="navbar"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link
                            to={'/' + lang}
                            className="navbar-logo"
                            title="Georgian Wine"
                        >
                            <img src={Logo} alt="Georgian Wine" />
                        </Link>
                        {/* Hamburger menu */}
                        <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${this.state.navBarActiveClass}`}
                    >
                        <div className="navbar-start"></div>
                        <div className="navbar-end">
                            <Link
                                className="navbar-item"
                                to={lang + '/' + menuTree.events[sel] + '/'}
                            >
                                <FormattedMessage id="header.events" />
                            </Link>
                            <Link
                                className="navbar-item"
                                to={lang + '/' + menuTree.app[sel] + '/'}
                            >
                                <FormattedMessage id="header.app" />
                            </Link>
                            <Link
                                className="navbar-item"
                                to={lang + '/' + menuTree.blog[sel] + '/'}
                            >
                                <FormattedMessage id="header.blog" />
                            </Link>
                            <a
                                className="navbar-item"
                                href="https://vinocorn.com/collections/all"
                            >
                                <button className="button is-white">
                                    <FormattedMessage id="header.shop" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header
