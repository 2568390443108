import React from 'react'
import { FormattedMessage } from 'react-intl'

const Footer = class extends React.Component {
    render() {
        return (
            <footer className="footer has-background-primary">
                <div className="container">
                    <div className="columns footer-column">
                        <div className="column is-24">
                            <div className="footer-center">
                                <h1 className="title is-1">
                                    <FormattedMessage id="georgian" />
                                </h1>
                            </div>

                            <div className="footer-center has-margin-top">
                                <FormattedMessage id="copyright" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
